





















import { defineComponent, onMounted, onBeforeUnmount } from 'vue';

import { usePopupOpener } from '../composition';
import bus from '@/bus';

import { useAppStore } from '@/store/appstore';
import { useBonusStore } from '@/modules/bonus/dist/store';

import BonusHomeSlotCard from './BonusHomeSlotCard.vue';
import BonusHomeSlotGroup from './BonusHomeSlotGroup.vue';

export default defineComponent({
  name: 'BonusHomeSlotButton',
  components: {
    BonusHomeSlotCard,
    BonusHomeSlotGroup,
  },
  setup() {
    const { openBonus } = usePopupOpener();

    const appStore = useAppStore();
    const bonusStore = useBonusStore();

    onMounted(() => {
      bus.$on('bonus:open-popup', openBonus);
    });

    onBeforeUnmount(() => {
      bus.$off('bonus:open-popup', openBonus);
    });

    return {
      openBonus,
      decimalOptions: appStore.getters.decimalOptions,
      user: appStore.getters.user,
      moduleEnabled: bonusStore.getters.moduleEnabled,
      settings: bonusStore.getters.settings,
    };
  },
});
