











































































































import { defineComponent, computed, ref, watch, nextTick, onMounted } from 'vue';
import { f7 } from 'framework7-vue';

import { IBranch, IService, idService } from 'src/types';
import { BookingFormFields, ISendBookingRequest } from '../types';

import validationState from 'src/validators/validation-state';
import { showErrorDialog } from 'src/misc';
import { sendBooking } from '../api';
import { useAppStore } from '@/store/appstore';
import { useBookingStore } from '../store';
import i18n from '@/translate/lang';

import ListInputDate from 'src/components/fields/list-input-date.vue';
import ListInputName from 'src/components/fields/list-input-name.vue';
import ListInputNumber from 'src/components/fields/list-input-number.vue';
import ListInputText from 'src/components/fields/list-input-text.vue';
import ListInputTextarea from 'src/components/fields/list-input-textarea.vue';
import ListInputTime from 'src/components/fields/list-input-time.vue';
import ListInputUsername from 'src/components/fields/list-input-username.vue';
import NavbarSlotLeft from 'src/components/navbar-slot-left.vue';

export default defineComponent({
  name: 'Booking',
  components: {
    ListInputText,
    ListInputDate,
    ListInputName,
    ListInputNumber,
    ListInputUsername,
    ListInputTextarea,
    ListInputTime,
    NavbarSlotLeft,
  },
  setup() {
    const validation = validationState();

    const appStore = useAppStore();
    const bookingStore = useBookingStore();

    const container = ref<HTMLElement | null>(null);

    const maskedUsername = ref('');
    const name = ref('');
    const branchField = ref('');
    const servicesField = ref('');
    const guests = ref<Nullable<string>>(null);
    const date = ref('');
    const time = ref('');
    const comment = ref('');

    const multipleBranches = ref<Array<Nullable<IBranch & { checked: boolean }>>>([]);
    const multipleServices = ref<IService[]>([]);

    const buttonClicked = ref(false);

    const showGuestsField = computed(() =>
      bookingStore.getters.settings.fields?.includes(BookingFormFields.guests),
    );
    const showBranchesField = computed(() =>
      bookingStore.getters.settings.fields?.includes(BookingFormFields.branch),
    );
    const showServicesField = computed(() =>
      bookingStore.getters.settings.fields?.includes(BookingFormFields.service),
    );

    function delayedBooking() {
      buttonClicked.value = true;
    }

    function onBranchChanged(branches: any) {
      multipleBranches.value = branches;
    }

    function onServicesChanged(services: any) {
      multipleServices.value = services;
    }

    function doSendBooking() {
      let params: ISendBookingRequest = {
        clientName: name.value,
        clientPhone: maskedUsername.value,
        visitDate: date.value,
        visitTime: time.value,
        comment: comment.value,
        idBranch: multipleBranches.value[0]?.idBranch,
      };

      if (showBranchesField.value) {
        params.idBranch =
          multipleBranches.value.find((branch) => branch?.checked)?.idBranch ||
          params.idBranch;
      }

      if (showServicesField.value) {
        params.services = multipleServices.value.reduce(
          (acc: idService[], item: IService) => {
            // @ts-ignore
            if (item.checked) {
              acc.push(item.idService);
            }
            return acc;
          },
          [],
        );
      }

      if (showGuestsField.value) {
        params.guests = guests.value ? parseInt(guests.value) || 0 : 0;
      }

      f7.preloader.show();

      sendBooking(params)
        .then(() => {
          f7.dialog.alert(
            i18n.t('modules.booking.form.result.text').toString(),
            bookingStore.getters.settings.title ||
              i18n.t('modules.booking.form.result.title').toString(),
            () => {
              f7.views.current.router.back();
            },
          );
        })
        .catch((error) => showErrorDialog({ error }))
        .finally(() => {
          f7.preloader.hide();
        });
    }

    function onSendClick() {
      buttonClicked.value = true;

      nextTick(() => {
        if (validation.validationState.invalid) {
          validation.touchAll();

          const invalidInput = container.value?.querySelector(
            '.list li:not(.multiple) .item-input-invalid input, .list li:not(.multiple) .item-input-invalid textarea',
          ) as HTMLInputElement;

          if (invalidInput) {
            invalidInput.focus();
          }

          return;
        }

        doSendBooking();
      });
    }

    function resetState() {
      maskedUsername.value = appStore.getters.user?.username
        ? appStore.getters.user.username !== 'Guest'
          ? appStore.getters.user.username
          : ''
        : '';
      name.value = appStore.getters.user?.fName || '';

      branchField.value = '';
      servicesField.value = '';

      guests.value = null;
      date.value = '';
      time.value = '';
      comment.value = '';

      multipleBranches.value = JSON.parse(
        JSON.stringify(bookingStore.getters.branches),
      ) as Array<IBranch & { checked: boolean }>;
      multipleServices.value = JSON.parse(JSON.stringify(bookingStore.getters.services));

      buttonClicked.value = false;
    }

    onMounted(() => {
      resetState();
    });

    watch(() => appStore.state.currentTab, resetState);

    return {
      maskedUsername,
      name,
      branchField,
      servicesField,
      guests,
      date,
      time,
      comment,
      multipleBranches,
      multipleServices,
      buttonClicked,
      showGuestsField,
      showBranchesField,
      showServicesField,
      delayedBooking,
      onBranchChanged,
      onServicesChanged,
      settings: bookingStore.getters.settings,
      appSettings: appStore.getters.appSettings,
      onSendClick,
      ...validation,
    };
  },
});
